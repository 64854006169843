import React from "react";
import InstantSearch from "@herb/components/Search/InstantSearch";
import { Index, connectInfiniteHits, Configure } from "react-instantsearch-dom";
import Image from "@herb/components/Image";
import { Link } from "gatsby";
import "./index.css";
const CustomHits = connectInfiniteHits(({ hits, brandsToShow = 3 }) => {
  const threeRandomNumbers = [];
  for (let i = 0; i < brandsToShow; i++) {
    threeRandomNumbers.push(Math.floor(Math.random() * hits.length) + 1);
  }

  return (
    <React.Fragment>
      <div className="articlePageRandomBrandsMainContainer">
        {brandsToShow === 2
          ? threeRandomNumbers.map(item => {
              return (
                <div className="articlePageSidebarRandomBrand">
                  <div className="articlePageSidebarRandomBrandCardContent">
                    <Image
                      className="lazyload articlePageSidebarBrandImageArticlePage"
                      src={hits[item - 1]?.contentBrands?.logo?.sourceUrl}
                    />

                    <p className="articlePageSidebarArticlePageBrandTitle">
                      {hits[item - 1]?.title}
                    </p>
                  </div>

                  <div className="articlePageSidebarArticlePageBrandButton">
                    <Link
                      to={`/brands/${hits[item - 1]?.slug}`}
                      css={{
                        fontStyle: "italic",
                        fontWeight: "300",
                        padding: "6px 18px",
                        fontSize: "20px",
                        lineHeight: "24px",
                        display: "inline-block",
                        background: "#3041FF",
                        color: "white"
                      }}
                    >
                      VIEW BRAND
                    </Link>
                  </div>
                </div>
              );
            })
          : threeRandomNumbers.map(item => {
              return (
                <div className="articlePageRandomBrand">
                  <div className="articlePageRandomBrandCardContent">
                    <Image
                      className="lazyload brandImageArticlePage"
                      src={hits[item - 1]?.contentBrands?.logo?.sourceUrl}
                    />

                    <h1 className="articlePageBrandTitle">
                      {hits[item - 1]?.title}
                    </h1>
                  </div>

                  <div className="articlePageBrandButton">
                    <Link
                      to={`/brands/${hits[item - 1]?.slug}`}
                      css={{
                        fontStyle: "italic",
                        fontWeight: "300",
                        padding: "6px 18px",
                        fontSize: "20px",
                        lineHeight: "24px",
                        display: "inline-block",
                        background: "#3041FF",
                        color: "white"
                      }}
                    >
                      VIEW BRAND
                    </Link>
                  </div>
                </div>
              );
            })}
      </div>
    </React.Fragment>
  );
});

const LoadRandomBrands = ({
  brandsToShow,
  showHeading = true,
  headingFontSize = "30px",
  borderTop = "1px solid black;"
}) => {
  return (
    <>
      <div
        css={{
          marginBottom: "20px",
          width: "100%"
        }}
        id="algolia"
      >
        {showHeading && (
          <p
            css={{ fontSize: headingFontSize }}
            className="articlePageFeaturedBrandHeading"
          >
            <span>Featured</span> Brands:
          </p>
        )}
        <InstantSearch>
          <Index indexName="Brands">
            <Configure
              aroundLatLngViaIP={false}
              hitsPerPage={200}
              query="*"
              filters={"isBrandActive.brandActive:true"}
            />
            <CustomHits brandsToShow={brandsToShow} />
          </Index>
        </InstantSearch>
      </div>
    </>
  );
};

export default LoadRandomBrands;
