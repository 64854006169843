import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "@herb/layouts";
import { paths, deployUrl } from "@herb/utils";
import Link from "@herb/components/Link";
import Image from "@herb/components/Image";
import Button from "@herb/components/Button";
import { Box, Container, Divider, Flex, Heading } from "@herb/ui";
import "./index.css";
import { Helmet } from "react-helmet";
import { scriptCreate } from "@herb/utils/scripts";

const Positions = ({ data }) => {
  const {
    allWordpressWpCareer: { edges }
  } = data;

  useEffect(() => {
    scriptCreate();
  });
  return (
    <Layout>
      <>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
      </>
      <Container css={{ flexDirection: "column", marginBottom: "80px" }}>
        <Box>
          <p className="positionsTitle">
            Herb is the world’s largest, most trusted cannabis community. Our
            vision is to engage with the world to burn the negative stigma in
            the cannabis lifestyle. We are looking for legends to help us make
            this dream a reality.
          </p>
        </Box>

        <Box css={{ marginTop: "100px" }}>
          <h2 className="joinUs">Join Us</h2>
          <Divider
            my={[4, 4, 4]}
            css={{
              border: "1px solid #000000"
              // marginTop: "25px",
              // marginBotttom: "25px"
            }}
          />
          {edges.map(({ node }, i) => (
            <React.Fragment key={`career-listing-${node.id}`}>
              <Flex
                alignItems="center"
                justifyContent="space-between"
                width={1}
                css={{
                  flexWrap: "wrap",
                  "@media screen and (max-width:768px)": {
                    flexDirection: "column",
                    alignItems: "flex-start"
                  }
                }}
              >
                <Heading
                  as="h3"
                  fontSize={3}
                  lineHeight="24px"
                  width={[1, 1 / 3]}
                  css={{
                    fontSize: "26px",
                    fontWeight: "700",
                    lineHeight: "32px"
                  }}
                >
                  <Link
                    className="positionsLinks"
                    to={`${paths.careers}/${(node || {}).slug}/`}
                  >
                    {node.title}
                  </Link>
                </Heading>
                {/* {node.contentCareers.location && (
                  <Flex
                    css={{
                      width: "30%",
                      flexWrap: "wrap"
                    }}
                  >
                    {node.contentCareers.location
                      .split(",")
                      .map((tag, index) => (
                        <Tag
                          key={index}
                          size="small"
                          css={{
                            marginRight: 8,
                            marginBottom: 8
                          }}
                        >
                          {tag}
                        </Tag>
                      ))}
                  </Flex>
                )} */}

                {/* <ReadMoreLink
                  //   width={[1, 1 / 3]}
                  to={`${paths.careers}/${node.slug}/`}
                  css={{
                    textTransform: "capitalize",
                    letterSpacing: "0px",
                    justifyContent: "flex-end",
                    "&:hover": {
                      cursor: "pointer"
                    }
                  }}
                >
                  View Position
                </ReadMoreLink> */}
                <Button
                  to={`${paths.careers}/${(node || {}).slug}/`}
                  type={"careerCardBtn"}
                >
                  View Details
                </Button>
              </Flex>
              {i !== edges.length - 1 && (
                <Divider
                  my={[4, 4, 4]}
                  css={{
                    border: "1px solid #000000"
                    // marginTop: "25px",
                    // marginBotttom: "25px"
                  }}
                />
              )}
            </React.Fragment>
          ))}
        </Box>
        <Flex
          css={{
            justifyContent: "center",
            alignItems: "space-between",
            flexWrap: "wrap",
            flexDirection: "row",
            marginTop: "104px",
            marginBottom: "50px",
            "@media (max-width: 425px)": {
              marginTop: "50px",
              flexDirection: "column"
            }
          }}
        >
          <Box
            width={[1, 1, 1, 1 / 2]}
            css={{
              position: "relative",
              marginTop: "60px",
              paddingRight: "16px",
              marginBottom: "24px",
              "@media (max-width: 796px)": {
                paddingRight: "0px"
              }
            }}
          >
            <p className="herbLife">Life At Herb</p>
            <h2 className="joinSesh-2">Join the sesh</h2>
            <p className="cultureForCareer">
              Culture is everything at Herb - to us it’s all about purpose,
              empathy, leadership, and creating an environment where people can
              reach their goals and become their best version.
            </p>
            <div className="positionsButton-2">
              <Button to="/careers" type={"careerButton"}>
                Learn about the Herb culture
              </Button>
            </div>

            {/* <Image
              className="lazyload"
              src="../images/career-art.png"
              css={{
                zIndex: "-1",
                top: "233px",
                left: "-18px",
                width: "267.18px",
                height: "252.59px",
                position: "absolute",
                "@media (max-width: 425px)": {
                  top: "300px",
                  left: "-60px"
                },
                "@media (max-width: 320px)": {
                  top: "400px",
                  left: "-60px"
                }
              }}
            /> */}
          </Box>
          <Box width={[1, 1, 1, 1 / 2]}>
            <Image
              className="lazyload"
              src="https://herb-platform-images.imgix.net/wp-content/uploads/2022/03/16093001/career-1.png"
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes"
              }}
              css={{
                width: "100%"
                // height: "440px"
              }}
            />
          </Box>
        </Flex>
      </Container>
    </Layout>
  );
};

export default Positions;
export const pageQuery = graphql`
  query {
    allWordpressWpCareer(sort: { fields: [title], order: [ASC] }) {
      edges {
        node {
          title
          id
          slug
          contentCareers {
            location
            description
            intro
          }
        }
      }
    }
  }
`;
