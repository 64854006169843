import React from "react";
import Imgix from "react-imgix";
import replaceImageCdn from "@herb/utils/replaceImageCdn";
// import lazySizes from "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
const Image = ({
  width,
  height,
  src,
  alt = "Loading...",
  attributes = {},
  ...restProps
}) => {
  const sourceUrl = src
    ? replaceImageCdn(src)
    : "https://herb-platform-images.imgix.net/wp-content/uploads/2023/01/12084032/dispensary-image.png";

  return (
    <Imgix
      className="lazyload"
      alt={alt}
      css={{ width: "100%" }}
      src={sourceUrl}
      attributeConfig={{ src: "data-src" }}
      htmlAttributes={{
        src: `https://herb-platform-images.imgix.net/wp-content/uploads/2022/09/20135726/Fallback-Image.png?w=${width}&h=${height}&q=10`,
        alt,
        ...attributes
      }}
      width={width}
      height={height}
      {...restProps}
    />
  );
};

export default Image;
