import React from "react";
import { Link } from "gatsby";
import Image from "@herb/components/Image";
import { orderBy } from "lodash";
import getCategoryPath from "@herb/utils/categoryPath";
import RedirectLink from "@herb/components/RedirectLink";
import "./index.css";

const BuyersGuide = ({ post }) => {
  const {
    categories: { nodes: categoryArray = [] },
    date,
    featuredImage,
    slug,
    content_post,
    title
  } = post;
  const [category] = orderBy(categoryArray, ["databaseId"], ["desc"]);
  const categoryPath = post.categories ? getCategoryPath(post.categories) : "";

  return (
    <div className="buyersGuideMainCompo">
      <div className="buyersGuideMainImage">
        <RedirectLink to={`/${categoryPath}/${(post || {}).slug}`}>
          <Image
            src={featuredImage?.node?.sourceUrl}
            style={{
              maxWidth: "100%",
              maxWeight: "100%",
              objectFit: "unset"
            }}
          />
        </RedirectLink>
      </div>
      <div className="cus-p-b-16px">
        <Link
          className="productCategoryLink"
          to={`/${getCategoryPath(post.categories)}`}
        >
          {category.name}
        </Link>
      </div>
      <div className="buyersGuideMainCompoContent">
        <RedirectLink to={`/${categoryPath}/${(post || {}).slug}`}>
          <h3 className="heading cus-p-b-16px">{title}</h3>
        </RedirectLink>

        <p className="subText cus-p-b-16px">{content_post.postExcerpt}</p>
      </div>
      <div className="articleReadMoreLink">
        <RedirectLink to={`/${categoryPath}/${(post || {}).slug}`}>
          read more
        </RedirectLink>
      </div>
    </div>
  );
};

export default BuyersGuide;
