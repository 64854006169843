import React from "react";
import Image from "@herb/components/Image";
import ReactHtmlParser from "react-html-parser";

import "./index.css";

const AriclePicture = ({ item }) => {
  const { image, imageLink } = item;

  return (
    <div className="ImageForArticle">
      {imageLink ? (
        <a href={imageLink} target="_blank" rel="noreferrer">
          <Image
            className="lazyload ArticleMainImage"
            src={(image || {}).sourceUrl}
            alt={(image || {}).altText}
            width={816}
            height={448}
            imgixParams={{ fit: "clip" }}
          />
        </a>
      ) : (
        <Image
          className="lazyload ArticleMainImage"
          src={(image || {}).sourceUrl}
          alt={(image || {}).altText}
          width={816}
          height={448}
          imgixParams={{ fit: "clip" }}
        />
      )}
      <div className="articleImageCredits">
        <div className="imageCourtesy">
          {ReactHtmlParser((image || {}).caption)}
        </div>
      </div>
    </div>
  );
};

export default AriclePicture;
