import React, { createContext, useReducer } from "react";
export const GlobalStateContext = createContext();
export const GlobalDispatchContext = createContext();

const initialState = {
  isHerbPlusFormSubmitted: false
};

function reducer(state, action) {
  switch (action.type) {
    case "HERB_PLUS_FORM_SUBMITTED": {
      return {
        ...state,
        isHerbPlusFormSubmitted: action.payload
      };
    }
    default: {
      throw new Error("Bad action type");
    }
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export default GlobalContextProvider;
