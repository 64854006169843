import React, { useEffect } from "react";
import Layout from "@herb/layouts";
import SEO from "@herb/components/seo";
import Newscard from "@herb/components/Cards/Newscard";
import Newsletter from "@herb/components/Newsletter";
import HomePageMegaArticle from "@herb/components/Article/HomePageMegaArticle";
import SectionHeading from "@herb/components/SectionHeading";
import ProductNav from "@herb/components/Navbar/ProductNav";
import ProductList from "@herb/components/ProductList";
import Button from "@herb/components/Button";
import { graphql, Link } from "gatsby";
import TrendingArticleCard from "@herb/components/Cards/TrendingArticleCard";
import StickySideArticle from "@herb/components/Article/StickySideArticle";
import StickySideNewsletter from "@herb/components/StickySideNewsletter";
import Container from "@herb/components/Container";
import LatestArticleLoadAlgolia from "@herb/components/Article/LatestArticleLoadAlgolia";
import Image from "@herb/components/Image";
import Featuredcard from "@herb/components/Cards/Featuredcard";
import { deployUrl } from "@herb/utils";
import { scriptCreate } from "@herb/utils/scripts";
import "@herb/components/Cards/CityCard/index.css";
import "../styles/index.css";
import "../styles/global.css";
const Slider = React.lazy(() => import("react-slick"));
const DisplayAd = React.lazy(() => import("@herb/components/DisplayAd"));

const Home = ({ data }) => {
  const isSSR = typeof window === "undefined";
  const {
    allWordpressWpPost,
    wordpressWpPage,
    allWordpressWpProductCategory: productCategories,
    allWordpressWpCategory: { nodes: cities = [] } = {}
  } = data;

  const {
    homepage,
    dealBannerHome,
    home420Collection
  } = wordpressWpPage?.template;
  const { dealBannerImageDesktop, dealBannerImageMobile } = dealBannerHome;
  const { trendingProducts } = homepage;
  const { topFeatureSection, secondaryFeatureSection } = homepage;
  const { showCityGuides, collectionContent } = home420Collection;

  var { nodes: latestArticles } = allWordpressWpPost;
  // Object for Richtext
  const stickyArticles = latestArticles.slice(0, 2);

  const richtext = {
    title:
      "Discover the best in cannabis. By entering your email below, you'll be the first to receive exclusive insider updates, delivered directly to your inbox."
  };

  // Object for Article

  // For Slider
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <svg
          className="citySlidertArrow"
          viewBox="0 0 32 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0.999999 1L31 21L0.999999 41" stroke="black" />
        </svg>
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <svg
          className="citySlidertArrow"
          viewBox="0 0 32 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M31 1L1 21L31 41" stroke="black" />
        </svg>
      </div>
    );
  }

  var settings = {
    dots: true,
    infinite: true,

    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  useEffect(() => {
    scriptCreate();
  }, []);

  return (
    <Layout path={`/`}>
      <SEO
        title="Weed Dispensaries, Strains, Culture & News | Herb"
        description="Herb is the go-to destination for weed discovery, dispensaries, strains, education, culture and community for cannabis and marijuana enthusiasts around the globe."
        url="/"
        canonical={`${deployUrl}`}
        breadcrumbs={[]}
      />
      {dealBannerImageDesktop?.sourceUrl && dealBannerImageMobile?.sourceUrl ? (
        <div className="dealImagesHP">
          <Link to="/deals">
            <Image
              className="dealBannerImageDesktop"
              alt="dealBannerImageDesktop"
              src={dealBannerImageDesktop.sourceUrl}
            />
          </Link>
          <Link to="/deals">
            <Image
              className="dealBannerImageMobile"
              src={dealBannerImageMobile.sourceUrl}
            />
          </Link>
        </div>
      ) : (
        <></>
      )}
      <div className="section1">
        <Featuredcard
          article={topFeatureSection}
          latestArticles={latestArticles}
        />
        <div className="cardContainer">
          <SectionHeading />
          <div className="sampleBlogsDiv">
            {(latestArticles.slice(0, 4) || []).map(obj => (
              <Newscard news={obj} key={obj.id} />
            ))}
          </div>
        </div>
      </div>
      <Container>
        {!isSSR && (
          <React.Suspense fallback={<div />}>
            <DisplayAd
              visibleProps={{
                margin: "40px 0px"
              }}
              adUnit="21810472093/Homepage/"
              banners={[
                {
                  breakpoint: "mobile",
                  sizes: [[320, 50]]
                },
                {
                  breakpoint: "tablet",
                  sizes: [[320, 50]]
                },
                {
                  breakpoint: "desktop",
                  sizes: [[728, 90]]
                }
              ]}
            />
          </React.Suspense>
        )}
      </Container>

      {/* Section3 */}
      <div className="section3">
        <SectionHeading heading="Trending" />
        <div className="section3Flex">
          <div className="newCardTrendingDiv">
            {(latestArticles.slice(5, 9) || []).map((obj, index) => (
              <TrendingArticleCard key={index} article={obj} />
            ))}
          </div>
          <div className="miniArticleDiv">
            <div className="articleForSide">
              {stickyArticles.map((item, index) => (
                <React.Fragment key={item.id}>
                  <StickySideArticle article={item} />
                  {index != stickyArticles.length - 1 && (
                    <hr className="maHr"></hr>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* Newsletter */}
      <div className="sectionNewsletter">
        <Newsletter richtext={richtext} />
      </div>
      {/* ad */}
      <Container>
        {!isSSR && (
          <React.Suspense fallback={<div />}>
            <DisplayAd
              visibleProps={{
                margin: "40px 0px"
              }}
              adUnit="21810472093/Homepage/"
              banners={[
                {
                  breakpoint: "mobile",
                  sizes: [[320, 50]]
                },
                {
                  breakpoint: "tablet",
                  sizes: [[320, 50]]
                },
                {
                  breakpoint: "desktop",
                  sizes: [[970, 250]]
                }
              ]}
            />
          </React.Suspense>
        )}
      </Container>
      {/* Cities Section */}
      <div className="section4">
        <div className="citySlider">
          <>
            {!isSSR && (
              <React.Suspense fallback={<div />}>
                {showCityGuides ? (
                  <Slider {...settings}>
                    {(cities || []).map((city, index) => (
                      <div className="cityImage">
                        <Link to={`/city-guides/${(city || {}).slug}`}>
                          <div className="cityBtnImage">
                            <Image
                              className="lazyload ImageOfCity"
                              height={520}
                              width={371}
                              imgixParams={{ fit: "clip", dpr: 1 }}
                              src={
                                (
                                  (city.content_category || {}).bannerImage ||
                                  {}
                                ).sourceUrl
                              }
                              alt={
                                (
                                  (city.content_category || {}).bannerImage ||
                                  {}
                                ).altText
                              }
                            />
                          </div>
                          <button className="cityBtn" role="link">
                            {city.name}
                          </button>
                        </Link>
                      </div>
                    ))}
                  </Slider>
                ) : (
                  <Slider {...settings}>
                    {(collectionContent || []).map((content, index) => (
                      <div className="cityImage">
                        <Link to={content.url}>
                          <div className="cityBtnImage">
                            <Image
                              className="lazyload ImageOfCity setImage"
                              height={520}
                              width={371}
                              imgixParams={{ fit: "clip", dpr: 1 }}
                              src={(content.image || {}).sourceUrl}
                              alt={(content.image || {}).altText}
                            />
                          </div>
                          <button className="cityBtn" role="link">
                            {content.buttonText}
                          </button>
                        </Link>
                      </div>
                    ))}
                  </Slider>
                )}
              </React.Suspense>
            )}
          </>
        </div>
      </div>
      {/* Section-5 */}
      {secondaryFeatureSection.featurePost && (
        <div className="section5">
          <HomePageMegaArticle article={secondaryFeatureSection.featurePost} />
        </div>
      )}

      {/* Section 7 */}
      <div className="section7">
        <div className="HomePageProducts">
          <SectionHeading heading="Products" />
          <ProductNav categories={productCategories} boutique={true} />
          <ProductList products={trendingProducts} />
          <div className="fullShopButton">
            <Button type={"boutiqueButton"} role="link" to="/products">
              full shop
            </Button>
          </div>
        </div>
      </div>
      {/* Section 8 */}
      <div className="section8">
        <SectionHeading heading="Latest" />
        <div className="section3Flex">
          <div className="newCardTrendingDiv">
            <LatestArticleLoadAlgolia posts={latestArticles} />
          </div>
          <div className="newsletterStickyDiv">
            <StickySideNewsletter />
          </div>
        </div>
        <div className="moreButton"></div>
      </div>
      <Container>
        {!isSSR && (
          <React.Suspense fallback={<div />}>
            <DisplayAd
              visibleProps={{
                margin: "40px 0px"
              }}
              adUnit="21810472093/Homepage/"
              banners={[
                {
                  breakpoint: "mobile",
                  sizes: [[320, 250]]
                },
                {
                  breakpoint: "tablet",
                  sizes: [[320, 250]]
                },
                {
                  breakpoint: "desktop",
                  sizes: [[970, 250]]
                }
              ]}
            />
          </React.Suspense>
        )}
      </Container>
    </Layout>
  );
};

export default Home;

export const pageQuery = graphql`
  query {
    ...allWordpressWpProductCategory

    wordpressWpPage(slug: { eq: "home" }) {
      id
      template {
        ... on WordpressWpTemplate_Home {
          templateName
          home420Collection {
            collectionContent {
              buttonText
              fieldGroupName
              url
              image {
                altText
                sourceUrl
              }
            }
            showCityGuides
          }
          dealBannerHome {
            dealBannerImageDesktop {
              sourceUrl
            }
            dealBannerImageMobile {
              sourceUrl
            }
          }
          homepage {
            topFeatureSection {
              sectionHeading
              fieldGroupName
              featuredVideo
              featureTitle
              featureLink
              featureCta
              featureBlurb

              featureImage {
                sourceUrl
                altText
                srcSet
                sizes
                date(formatString: "DD,MM,YYYY")
              }
            }
            trendingProducts {
              ... on WordpressWpProduct {
                ...Product
              }
            }
            secondaryFeatureSection {
              sectionHeading
              featurePost {
                ...Post
              }
            }
            bottomFeatureSection {
              sectionHeading
              featurePost {
                ...Post
              }
            }
          }
        }
      }
    }

    allWordpressWpPost(sort: { fields: date, order: DESC }, limit: 9) {
      nodes {
        ...Post
      }
    }

    allWordpressWpCategory(
      filter: {
        wpParent: { node: { slug: { eq: "city-guides" } } }
        count: { gt: 0 }
      }
    ) {
      nodes {
        id
        name
        slug
        content_category {
          bannerImage {
            sourceUrl
            altText
          }
        }
      }
    }

    allSeries {
      edges {
        node {
          id
          slug
          title
          bannerImage
          logoImage
          coverImage
          shortDescription
          videos {
            mediaid
            title
            description
            image
          }
        }
      }
    }
  }
`;
