import React from "react";
import Image from "@herb/components/Image";
import DynamicLink from "@herb/components/DynamicLink";
import "./index.css";

const MiddleSectionCard = ({ article }) => {
  const {
    featureTitle,
    featureBlurb,
    featureLink = "",
    featureImage = {}
  } = article;
  const internal = !!featureLink.match(/^https?:\/\/(www\.)?herb\.co/g);
  const internalLink = featureLink.replace(/^https?:\/\/(www\.)?herb\.co/g, "");
  return (
    <div className="productsMiddleSection">
      <div className="productMiddleSectionFeaturedCard">
        <DynamicLink
          style={{ color: "inherit" }}
          to={internal ? internalLink : featureLink}
        >
          <div className="productMiddleSectionFeaturedImage">
            <Image
              src={featureImage.sourceUrl}
              alt={featureImage.altText}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "unset"
              }}
            />
          </div>
          <div className="productMiddleSectionFeaturedTitle">
            {featureTitle}
          </div>
          <div className="productMiddleSectionFeaturedBlurb">
            {featureBlurb}
          </div>
        </DynamicLink>
      </div>
    </div>
  );
};

export default MiddleSectionCard;
