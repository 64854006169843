export const fademdUrls = [
  "/guides/how-much-is-a-quarter-of-weed/",
  "/learn/strongest-strains/",
  "/learn/how-to-grow-cannabis-a-step-by-step-guide-for-beginners/",
  "/guides/how-long-does-weed-stay-in-your-urine/",
  "/guides/how-to-pass-a-drug-test/",
  "/learn/quick-easy-cannabutter/",
  "/learn/best-rolling-papers/",
  "/learn/best-bongs/",
  "/guides/the-best-grinders-on-the-market/",
  "/guides/how-to-roll-a-joint/",
  "/news/culture/weed-memes/",
  "/learn/best-detox-drinks-for-weed/",
  "/learn/indica-vs-sativa-whats-the-difference/",
  "/guides/how-to-decarb-weed/",
  "/guides/flying-with-weed-airport-security/",
  "/learn/cannabis-fan-leaves/",
  "/learn/strongest-indica-strains/",
  "/guides/how-to-smoke-hash/",
  "/guides/best-thc-disposables/",
  "/learn/cannabis-nodes/",
  "/guides/how-to-roll-a-backwoods-blunt/",
  "/learn/best-led-grow-lights/",
  "/learn/best-homemade-bug-spray-for-weed-plants/",
  "/guides/how-to-make-shatter/",
  "/guides/the-best-dry-herb-vaporizers/",
  "/learn/best-grow-box-for-beginners/",
  "/news/health/dry-herb-vs-concentrate-vaporizers/",
  "/guides/quickest-detox-thc-system/",
  "/learn/kind-led-led-vs-hps/",
  "/learn/smoke-shatter/",
  "/news/culture/10-things-that-weirdly-smell-like-weed/",
  "/learn/how-to-clean-a-bong/",
  "/guides/how-to-germinate-cannabis-seeds/",
  "/guides/best-smell-proof-weed-containers/",
  "/guides/best-pipes/",
  "/learn/what-is-hydroponic-weed/",
  "/guides/how-to-buy-weed-online/",
  "/learn/laced-weed/",
  "/learn/how-to-clone-weed/",
  "/guides/how-to-unclog-a-vape-pen/",
  "/guides/how-to-smoke-hash-oil/",
  "/news/culture/weed-snoop/",
  "/guides/the-most-exotic-cannabis-strains-out-there/",
  "/learn/bongs-vs-pipes-best-new-smokers/",
  "/learn/certo-detox-everything-you-need-to-know/",
  "/learn/what-are-moon-rocks/",
  "/guides/weed-apartment-growing/",
  "/guides/smoke-tricks-how-to-french-inhale-and-blow-os/",
  "/guides/how-to-make-a-bong/",
  "/guides/how-to-roll-a-dutch-master-blunt/",
  "/learn/best-weed-fertilizers/",
  "/learn/why-does-weed-make-you-tired/",
  "/guides/the-best-electronic-dab-rigs/",
  "/learn/weed-mold-look-like/",
  "/news/culture/high-thoughts/",
  "/learn/how-long-do-edibles-stay-in-your-system/",
  "/learn/the-cannabis-bud-ripening-process/",
  "/guides/make-glass-pipe-bowl-bong/",
  "/guides/best-candy-strains/",
  "/learn/best-durable-pipes/",
  "/learn/what-to-make-with-cannabutter/",
  "/guides/male-flowers/",
  "/learn/what-is-dabbing/",
  "/learn/buy-edibles-online-ship-anywhere/",
  "/guides/prescription-medications-avoid-mixing-weed/",
  "/news/culture/cannabis-world-records/",
  "/guides/growing-cannabis-seeds/",
  "/guides/how-to-hide-the-smell-of-weed/",
  "/learn/what-are-percolators-and-how-do-they-help/",
  "/learn/niacin-detox-everything-you-need-to-know/",
  "/learn/autoflowering-cannabis-seeds/",
  "/news/culture/weed-scale-app-weigh-weed-smartphone/",
  "/learn/happens-liver-eat-edibles/",
  "/learn/smoking-get-higher-high-longer/",
  "/learn/best-blunt-wraps/",
  "/guides/how-to-make-a-gravity-bong/",
  "/learn/how-much-is-an-ounce-of-weed-prices-worth-measurement/",
  "/guides/whats-in-your-pen-choosing-your-vape-cart-wisely/",
  "/guides/smoke-weed-inside-without-getting-caught/",
  "/guides/best-510-cartridge-batteries/",
  "/guides/the-full-guide-to-the-best-white-weed-strains-out-there/",
  "/learn/best-home-drug-test-kit/",
  "/news/is-pro-mix-a-good-soil-for-cannabis-which-type-to-use/",
  "/learn/best-dab-torch/",
  "/learn/weed-games/",
  "/learn/best-weed-drinks/",
  "/news/sports/gold-medal-olympians/",
  "/news/culture/the-best-movies-to-watch-on-mushrooms/",
  "/guides/how-to-get-rid-of-the-munchies/",
  "/learn/bud-colors/",
  "/news/culture/strains-rappers-smoke/",
  "/learn/best-stash-bags/",
  "/learn/indoor-grow-room/",
  "/learn/strongest-hybrid-strains/",
  "/guides/how-to-make-a-grinder/",
  "/learn/what-is-marijuana-wax/",
  "/guides/how-to-re-moisten-dry-weed/",
  "/learn/kief-6-great-ways-use/",
  "/guides/best-way-get-rid-resin-stains/",

  "/guides/how-much-is-a-quarter-of-weed",
  "/learn/strongest-strains",
  "/learn/how-to-grow-cannabis-a-step-by-step-guide-for-beginners",
  "/guides/how-long-does-weed-stay-in-your-urine",
  "/guides/how-to-pass-a-drug-test",
  "/learn/quick-easy-cannabutter",
  "/learn/best-rolling-papers",
  "/learn/best-bongs",
  "/guides/the-best-grinders-on-the-market",
  "/guides/how-to-roll-a-joint",
  "/news/culture/weed-memes",
  "/learn/best-detox-drinks-for-weed",
  "/learn/indica-vs-sativa-whats-the-difference",
  "/guides/how-to-decarb-weed",
  "/guides/flying-with-weed-airport-security",
  "/learn/cannabis-fan-leaves",
  "/learn/strongest-indica-strains",
  "/guides/how-to-smoke-hash",
  "/guides/best-thc-disposables",
  "/learn/cannabis-nodes",
  "/guides/how-to-roll-a-backwoods-blunt",
  "/learn/best-led-grow-lights",
  "/learn/best-homemade-bug-spray-for-weed-plants",
  "/guides/how-to-make-shatter",
  "/guides/the-best-dry-herb-vaporizers",
  "/learn/best-grow-box-for-beginners",
  "/news/health/dry-herb-vs-concentrate-vaporizers",
  "/guides/quickest-detox-thc-system",
  "/learn/kind-led-led-vs-hps",
  "/learn/smoke-shatter",
  "/news/culture/10-things-that-weirdly-smell-like-weed",
  "/learn/how-to-clean-a-bong",
  "/guides/how-to-germinate-cannabis-seeds",
  "/guides/best-smell-proof-weed-containers",
  "/guides/best-pipes",
  "/learn/what-is-hydroponic-weed",
  "/guides/how-to-buy-weed-online",
  "/learn/laced-weed",
  "/learn/how-to-clone-weed",
  "/guides/how-to-unclog-a-vape-pen",
  "/guides/how-to-smoke-hash-oil",
  "/news/culture/weed-snoop",
  "/guides/the-most-exotic-cannabis-strains-out-there",
  "/learn/bongs-vs-pipes-best-new-smokers",
  "/learn/certo-detox-everything-you-need-to-know",
  "/learn/what-are-moon-rocks",
  "/guides/weed-apartment-growing",
  "/guides/smoke-tricks-how-to-french-inhale-and-blow-os",
  "/guides/how-to-make-a-bong",
  "/guides/how-to-roll-a-dutch-master-blunt",
  "/learn/best-weed-fertilizers",
  "/learn/why-does-weed-make-you-tired",
  "/guides/the-best-electronic-dab-rigs",
  "/learn/weed-mold-look-like",
  "/news/culture/high-thoughts",
  "/learn/how-long-do-edibles-stay-in-your-system",
  "/learn/the-cannabis-bud-ripening-process",
  "/guides/make-glass-pipe-bowl-bong",
  "/guides/best-candy-strains",
  "/learn/best-durable-pipes",
  "/learn/what-to-make-with-cannabutter",
  "/guides/male-flowers",
  "/learn/what-is-dabbing",
  "/learn/buy-edibles-online-ship-anywhere",
  "/guides/prescription-medications-avoid-mixing-weed",
  "/news/culture/cannabis-world-records",
  "/guides/growing-cannabis-seeds",
  "/guides/how-to-hide-the-smell-of-weed",
  "/learn/what-are-percolators-and-how-do-they-help",
  "/learn/niacin-detox-everything-you-need-to-know",
  "/learn/autoflowering-cannabis-seeds",
  "/news/culture/weed-scale-app-weigh-weed-smartphone",
  "/learn/happens-liver-eat-edibles",
  "/learn/smoking-get-higher-high-longer",
  "/learn/best-blunt-wraps",
  "/guides/how-to-make-a-gravity-bong",
  "/learn/how-much-is-an-ounce-of-weed-prices-worth-measurement",
  "/guides/whats-in-your-pen-choosing-your-vape-cart-wisely",
  "/guides/smoke-weed-inside-without-getting-caught",
  "/guides/best-510-cartridge-batteries",
  "/guides/the-full-guide-to-the-best-white-weed-strains-out-there",
  "/learn/best-home-drug-test-kit",
  "/news/is-pro-mix-a-good-soil-for-cannabis-which-type-to-use",
  "/learn/best-dab-torch",
  "/learn/weed-games",
  "/learn/best-weed-drinks",
  "/news/sports/gold-medal-olympians",
  "/news/culture/the-best-movies-to-watch-on-mushrooms",
  "/guides/how-to-get-rid-of-the-munchies",
  "/learn/bud-colors",
  "/news/culture/strains-rappers-smoke",
  "/learn/best-stash-bags",
  "/learn/indoor-grow-room",
  "/learn/strongest-hybrid-strains",
  "/guides/how-to-make-a-grinder",
  "/learn/what-is-marijuana-wax",
  "/guides/how-to-re-moisten-dry-weed",
  "/learn/kief-6-great-ways-use",
  "/guides/best-way-get-rid-resin-stains"
];

export const stateLabelValues = [
  { label: "Alabama", value: "AL" },
  { label: "Alaska", value: "AK" },
  { label: "American Samoa", value: "AS" },
  { label: "Arizona", value: "AZ" },
  { label: "Arkansas", value: "AR" },
  { label: "British Columbia", value: "BC" },
  { label: "California", value: "CA" },
  { label: "Colorado", value: "CO" },
  { label: "Connecticut", value: "CT" },
  { label: "Delaware", value: "DE" },
  { label: "District of Columbia", value: "DC" },
  { label: "States of Micronesia", value: "FM" },
  { label: "Florida", value: "FL" },
  { label: "Georgia", value: "GA" },
  { label: "Guam", value: "GU" },
  { label: "Hawaii", value: "HI" },
  { label: "Idaho", value: "ID" },
  { label: "Illinois", value: "IL" },
  { label: "Indiana", value: "IN" },
  { label: "Iowa", value: "IA" },
  { label: "Kansas", value: "KS" },
  { label: "Kentucky", value: "KY" },
  { label: "Louisiana", value: "LA" },
  { label: "Manitoba", value: "MB" },
  { label: "Maine", value: "ME" },
  { label: "Marshall Islands", value: "MH" },
  { label: "Maryland", value: "MD" },
  { label: "Maui", value: "MA" },
  { label: "Michigan", value: "MI" },
  { label: "Michigan", value: "MI" },
  { label: "Minnesota", value: "MN" },
  { label: "Massachusetts", value: "MT" },
  { label: "Mississippi", value: "MS" },
  { label: "Missouri", value: "MO" },
  { label: "Montana", value: "MT" },
  { label: "Nebraska", value: "NE" },
  { label: "Nevada", value: "NV" },
  { label: "New Hampshire", value: "NH" },
  { label: "New Jersey", value: "NJ" },
  { label: "New Mexico", value: "NM" },
  { label: "New York", value: "NY" },
  { label: "North Carolina", value: "NC" },
  { label: "North Dakota", value: "ND" },
  { label: "Northern Mariana Islands", value: "MP" },
  { label: "Ohio", value: "OH" },
  { label: "Oklahoma", value: "OK" },
  { label: "Ontario", value: "ON" },
  { label: "Oregon", value: "OR" },
  { label: "Palau", value: "PW" },
  { label: "Pennsylvania", value: "PA" },
  { label: "Puerto Rico", value: "PR" },
  { label: "Quebec", value: "QC" },
  { label: "Rhode Island", value: "RI" },
  { label: "South Carolina", value: "SC" },
  { label: "South Dakota", value: "SD" },
  { label: "Saskatchewan", value: "SK" },
  { label: "Tennessee", value: "TN" },
  { label: "Texas", value: "TX" },
  { label: "Utah", value: "UT" },
  { label: "Vermont", value: "VT" },
  { label: "Virgin Islands", value: "VI" },
  { label: "Virginia", value: "VA" },
  { label: "Washington", value: "WA" },
  { label: "West Virginia", value: "WV" },
  { label: "Wisconsin", value: "WI" },
  { label: "Wyoming", value: "WY" }
];
