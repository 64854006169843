import React from "react";
import Image from "@herb/components/Image";

const FadeMDCard = () => {
  return (
    <div
      css={{
        margin: "100px auto auto auto"
      }}
    >
      <a href="https://fademd.com/" target="_blank">
        <Image
          src="https://herb-platform-images.imgix.net/wp-content/uploads/2023/05/30085212/FadeMD-Medical-Marijuana-Card-1.png"
          alt="Get Med Card"
        />
      </a>
    </div>
  );
};

export default FadeMDCard;
