import React from "react";
import { Link } from "gatsby";
import { Tab } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Image from "@herb/components/Image";
import "./index.css";

const TopBrandCard = ({ brand, index }) => {
  const { title, logo, slug } = brand;
  return (
    <Tab className={"brandTabList"}>
      <Link to={`/brands/${slug}`}>
        <div className="topBrandCardMain">
          <div className="brandIndex">{index + 1}.</div>
          <div className="brandTitle">{title}</div>
          <div className="brandImageDiv">
            <Image className={"lazyload"} src={logo} alt={""} width={100} />
          </div>
        </div>
      </Link>
    </Tab>
  );
};

export default TopBrandCard;
