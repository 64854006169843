import React from "react";
import styled from "@emotion/styled";
import ReactHtmlParser from "react-html-parser";
import { themeGet } from "styled-system";

import { Box } from "@herb/ui";

const Embed = styled(Box)`
  iframe:not([src*="facebook.com"]),
  iframe:not([src*="jwplatform.com"]) {
    display: block;
    width: 100%;
    border-style: none;
    margin: 0;

    @media (max-width: ${themeGet("breakpoints.0")}) {
      height: 330px;
    }
  }

  iframe[src*="facebook.com"] {
    display: block;
    max-width: 100% !important;
    margin: 15px auto !important;
  }
`;

export default ({ html }) => <Embed pb={[3, 4]}>{ReactHtmlParser(html)}</Embed>;
