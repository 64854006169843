import React from "react";
import { Link } from "gatsby";
import { Tab } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { track } from "@herb/utils/analytics";
import "./index.css";
import Image from "@herb/components/Image";
import productLink from "@herb/utils/productLink";

const ProductHypeCard = ({ product, index }) => {
  const { title, slug } = product;
  const { productThumbnail } = product.contentProducts;

  const trackShopButtonEvent = to => () => {
    let analytics = { locationOnPage: "Product Page Affiliate CTA" };

    if (typeof window !== undefined) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "Shop Button Clicked"
      });
    }

    track("Shop Button Click", {
      to,
      ...(analytics || {})
    });

    return true;
  };

  return (
    <div>
      <Tab className={"productTabList"}>
        <Link
          analytics={trackShopButtonEvent(product.contentProducts.affiliateUrl)}
          to={`${productLink(product)}`}
          style={{ color: "inherit" }}
        >
          <div className="productHypeCardMain">
            <div className="productIndex">{index + 1}.</div>
            <div className="productTitle">{title}</div>
            <div className="productImageDiv">
              <Image
                className={"lazyload productHypeImage"}
                src={productThumbnail.sourceUrl}
                alt={""}
                width={100}
              />
            </div>
          </div>
        </Link>
      </Tab>
    </div>
  );
};

export default ProductHypeCard;
