import React from "react";
import { Link } from "gatsby";
import Image from "@herb/components/Image";
import { track } from "@herb/utils/analytics";
import productLink from "@herb/utils/productLink";
import Button from "@herb/components/Button";
import "./index.css";

const ProductCard = ({ productdetails, cardStyles, searchModal, item }) => {
  const { acf, contentProducts } = productdetails;

  const image = acf
    ? (acf || {}).product_images[0]
    : (contentProducts || {}).productImages[0];
  const sourceUrl = image?.sourceUrl || image?.source_url;
  const altText = (image || {}).altText || (image || {}).alt_text;
  const { productCategory: category, affiliateUrl, brand } = contentProducts;

  const url = productLink(productdetails)
    .toLocaleLowerCase()
    .replace(/(.*[^/])$/, "$1/");

  const trackShopButtonEvent = to => () => {
    let analytics = { locationOnPage: "Product Page Affiliate CTA" };

    if (typeof window !== undefined) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "Shop Button Clicked"
      });
    }

    track("Shop Button Click", {
      to,
      ...(analytics || {})
    });

    return true;
  };

  return (
    <div
      className={searchModal ? "searchProductsWidth" : "mSingleProduct"}
      style={{
        ...cardStyles
      }}
    >
      <Link
        to={`/products/${category.slug}/${brand?.slug}-${productdetails.slug}`}
        onClick={() => {
          if (typeof window !== undefined) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "View Product"
            });
          }
          track("Read More Link", {
            url
          });
        }}
        style={{ color: "inherit" }}
      >
        <div className="mProductImg">
          <Image
            className="lazyload mImg"
            src={sourceUrl || image}
            alt={altText || "product"}
            height={334}
            width={295}
            imgixParams={{ fit: "clip" }}
          />
        </div>
      </Link>

      <div className="mProductDetails">
        <Link className="mCategory" to={`/products/${(category || {}).slug}/`}>
          {(category || {}).name}
        </Link>

        <Link
          to={`${productLink(productdetails)}`}
          style={{ color: "inherit" }}
        >
          <h4 className="mTitle">{productdetails.title}</h4>
        </Link>
      </div>

      <div className="buyCardProduct">
        <Button
          href={affiliateUrl}
          analytics={trackShopButtonEvent(affiliateUrl)}
          target="_blank"
          link
          stlyes={{
            fontStyle: "italic",
            padding: "6px 18px",
            fontSize: "20px",
            lineHeight: "24px",
            display: "inline-block",
            color: " #141313"
          }}
          type={"boutiqueButton"}
          rel="noopener noreferrer"
        >
          shop now
        </Button>
      </div>

      <Link
        href={`${productLink(productdetails)}`}
        css={{
          fontStyle: "italic",
          fontWeight: "400",
          fontSize: "22px",
          lineHeight: "24px",
          display: "inline-block",
          textDecorationLine: "underline",
          textTransform: "uppercase",
          color: "#141313",
          fontFamily: "Source Code Pro"
        }}
      >
        View Product
      </Link>
    </div>
  );
};

export default ProductCard;
