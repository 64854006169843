import React from "react";
import ReactHtmlParser from "react-html-parser";
import slugify from "slugify";
import Image from "@herb/components/Image";
import { paths } from "@herb/utils";
import RedirectLink from "@herb/components/RedirectLink";
import CategoryAndDate from "@herb/components/CategoryAndDate";

const AuthorArticleTile = ({ article, ...props }) => (
  <div className="newCardTrending">
    <div className="cntPic">
      <RedirectLink
        to={`${
          paths.legacy[slugify(article.categories[0], { lower: true })]
        }/${article.post_name || (article || {}).slug}/`}
      >
        <Image
          className={"lazyload nctImg"}
          src={
            (article.featuredImage || {}).sourceUrl ||
            "../assets/Rectangle 15.jpg"
          }
          alt={(article.featuredImage || {}).altText}
          css={{
            width: "100%"
          }}
        />
      </RedirectLink>
    </div>

    <div className="nctContent">
      <CategoryAndDate
        path={paths.legacy[slugify(article.categories[0], { lower: true })]}
        date={(article || {}).date}
        category={article.categories}
      />
      <RedirectLink
        to={`${
          paths.legacy[slugify(article.categories[0], { lower: true })]
        }/${article.post_name || (article || {}).slug}/`}
      >
        <h3 className="nctHeading">{ReactHtmlParser((article || {}).title)}</h3>
        <p className="nctText">{ReactHtmlParser((article || {}).excerpt)}</p>
      </RedirectLink>
    </div>
  </div>
);

export default AuthorArticleTile;
