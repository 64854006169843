import React from "react";
import { Link } from "gatsby";
import Image from "@herb/components/Image";
import RedirectLink from "@herb/components/RedirectLink";
import productLink from "@herb/utils/productLink";
import Button from "@herb/components/Button";
import { track } from "@herb/utils/analytics";
import "./index.css";

const ProductHit = hit => {
  const { acf, contentProducts } = hit;
  const image = acf ? acf.product_images[0] : contentProducts.productThumbnail;

  const { productCategory: category, affiliateUrl,brand } = contentProducts;
  return (
    <div
      className="mSingleProduct"
      css={{
        width: "32%",
        "@media screen and (max-width:768px)": {
          width: "48%"
        }
      }}
    >
      <Link to={`/products/${category.slug}/${brand?.slug}-${hit.slug}`}>
        <div className="mProductImg">
          <Image
            className="lazyload mImg"
            sizes="(min-width: 40em) 30vw, 40vw"
            src={image?.source_url}
            alt={image?.alt_text}
            height={217}
            width={192}
            imgixParams={{
              ar: "4:3",
              crop: "focalpoint",
              fit: "crop"
            }}
            attributeConfig={{
              src: "data-src",
              srcSet: "data-srcset",
              sizes: "data-sizes"
            }}
          />
        </div>
        <div className="mProductDetails">
          <p className="mCategory">{(category || {}).name}</p>
          <h4 className="mTitle">{hit.title}</h4>
        </div>
      </Link>
      <div className="buyHitProduct">
        <Button
          href={affiliateUrl}
          target="_blank"
          link
          stlyes={{
            fontStyle: "italic",
            padding: "6px 18px",
            fontSize: "20px",
            lineHeight: "24px"
          }}
          type="boutiqueButton"
          rel="noopener noreferrer"
        >
          shop now
        </Button>
      </div>
      <Link className="viewProductHitCard" href={productLink(hit)}>
        View Product
      </Link>
    </div>
  );
};

export default ProductHit;
