import React, { useEffect } from "react";
import SEO from "@herb/components/seo";
import Layout from "@herb/layouts";
import Container from "@herb/components/Container";
// import ProfileCard from "@herb/components/Cards/ProfileCard";
import Button from "@herb/components/Button";
import Fade from "react-reveal/Fade";
import Image from "@herb/components/Image";
import $ from "jquery";
import { deployUrl } from "@herb/utils";
import { scriptCreate } from "@herb/utils/scripts";
import "./index.css";

const AboutUs = () => {
  useEffect(() => {
    $(window).on("load resize scroll", function() {
      $(".scroll").each(function() {
        var scroll = $(window).scrollTop();
        var elementTop = $(".scroll").offset().top;
        var topPosition = scroll - elementTop;
        $(".moving").css({ bottom: topPosition });
        $(".moving-left").css({ left: topPosition });
        $(".moving-right").css({ right: topPosition });
        $(".moving-bottom").css({ top: topPosition });
      });

      $(".scroll2").each(function() {
        var scroll = $(window).scrollTop();
        var elementTop = $(".scroll2").offset().top;
        var topPosition = scroll - elementTop;
        $(".moving-top-2").css({ bottom: topPosition });
        // $(".moving-bottom-2").css({ top: topPosition });
      });
    });

    scriptCreate();
  }, []);

  const breadcrumbs = [
    {
      name: "About Us",
      url: `/about-us/`
    }
  ];

  return (
    <div className="aboutUsBgColor">
      <Layout>
        <SEO
          title="About Us"
          description="The easy way to discover weed"
          breadcrumbs={breadcrumbs}
          url={`/about-us`}
          canonical={`${deployUrl}/about-us`}
        />
        <Container>
          <Fade bottom>
            <div className="aboutUsTextBanner">
              <h1 className="aboutTitle">
                Herb is a culture discovery platform.
              </h1>
              <p className="preText1">
                we’re a collective of tastemakers, curators, and educators.
              </p>
              <p className="preText2">
                together we’re shaping the industry and redefining weed.
              </p>
              <p className="preText3">we are herb.</p>
            </div>
          </Fade>
          <section className="textWithImage">
            <div className="imageWithText1">
              <Fade bottom>
                <div className="forOnlyImageOne">
                  <Image
                    alt="/"
                    src="https://herb-platform-images.imgix.net/wp-content/uploads/2022/07/04091006/whoWeAre.webp"
                    className="textWithPicture lazyload"
                  />
                </div>
              </Fade>
              <Fade bottom>
                <div className="forOnlyText1">
                  <h2 className="onlyTextH2">Who We Are</h2>
                  <p className="onlyTextP1">
                    Herb is an online cannabis community that seeks to breakdown
                    the stigma around weed by acting as a thoughtful curator of
                    cannabis products, experiences and culture.
                  </p>
                  <p className="onlyTextP2">
                    We help users discover the best products, new products,
                    information on strains, how-to guides and experiences in
                    their cities through content and community.
                  </p>
                </div>
              </Fade>
            </div>
            <div className="imageWithText2">
              <Fade bottom>
                <div className="forOnlyImageTwo">
                  <img
                    alt="/"
                    src="https://herb-platform-images.imgix.net/wp-content/uploads/2022/05/19115112/image2.jpg"
                    className="textWithPictureTwo"
                  />
                </div>
              </Fade>
              <Fade bottom>
                <div className="forOnlyText2">
                  <h2 className="onlyTextH2-2">Our Vision</h2>
                  <p className="onlyTextP1-2">
                    To create the largest and most beloved cannabis brand, with
                    a strong community of like-minded members who love cannabis,
                    care about criminal justice and appreciate the finer things.
                  </p>
                  <h2 className="onlyTextH2-2">Our Mission</h2>
                  <p className="onlyTextP2-2">
                    Our mission is to help users free their minds and inspire
                    them to live their best lives through discovering products,
                    content and experiences that delight, surprise and
                    enlighten.
                  </p>

                  <p className="onlyTextP3-2">
                    We want our unique community members to feel not only
                    delighted but a strong sense of belonging; that they have
                    found their people, and trust that we are curating the best
                    experiences for them.
                  </p>
                </div>
              </Fade>
            </div>
          </section>
          {/* <section className="profileCardSection">
            <div className="profileCardInnerDiv">
              <ProfileCard
                image={
                  "https://herb-platform-images.imgix.net/wp-content/uploads/2022/09/15182716/Matt-Pic.jpg"
                }
                name={"Matt Gray"}
                position={"Founder & CEO"}
                twitter={`https://twitter.com/matt_gray_`}
                linkedIn={"https://www.linkedin.com/in/mattgray1"}
              />
              <ProfileCard
                image={
                  "https://herb-platform-images.imgix.net/wp-content/uploads/2022/09/23115254/IMG_7702.jpg"
                }
                name={"Ben Meer"}
                position={"Chief Creative Officer"}
                twitter={`http://twitter.com/systemsunday`}
              />
              <ProfileCard
                image={
                  "https://herb-platform-images.imgix.net/wp-content/uploads/2022/10/25175844/IMG_3972.jpg"
                }
                name={"Codie Sanchez"}
                position={"Chief Commercial Officer"}
                twitter={`https://twitter.com/Codie_Sanchez`}
                // linkedIn={"https://www.linkedin.com/in/mattgray1"}
              />
            </div>
          </section> */}
          <Fade bottom>
            <section className="forTextWithButton">
              <h2 className="TextWithButtonH2">
                Creating a community for impact
              </h2>
              <p className="TextWithButtonP">
                The team at Herb shares the passion for cannabis, and its
                legalisation, and are working to bring cannabis to the
                mainstream.
              </p>
              <p className="TextWithButtonP1">
                We’re constant learners who thrive on change and seek to have an
                impact in everything we do.
              </p>
              <Button type={"aboutUsPageBtn"} to="/careers">
                work with us
              </Button>
            </section>
          </Fade>

          <section className="parallaxImages1 scroll">
            <div className="fourParallaxImages" style={{ display: "flex" }}>
              <div>
                <Image
                  className="lazyload parallaxImage1 moving-left"
                  src="https://herb-platform-images.imgix.net/wp-content/uploads/2022/07/04091415/Rectangle-104.webp"
                  alt="/"
                  css={{
                    height: "320px",
                    width: "385px",
                    "@media screen and (max-width:1024px)": {
                      height: "280px",
                      width: "325px"
                    },
                    "@media screen and (max-width:768px)": {
                      height: "185px",
                      width: "220px",
                      left: "15% !important"
                    },
                    "@media screen and (max-width:425px)": {
                      height: "185px",
                      width: "220px",
                      left: "0 !important",
                      marginLeft: "0"
                    }
                  }}
                />
              </div>
              <div>
                <Image
                  className="lazyload parallaxImage2 moving-bottom-2"
                  src="https://herb-platform-images.imgix.net/wp-content/uploads/2022/05/19115115/image4.jpg"
                  alt="/"
                  css={{
                    height: "390px",
                    width: "345px",
                    marginTop: "200px",
                    "@media screen and (max-width:1024px)": {
                      height: "335px",
                      width: "290px"
                    },
                    "@media screen and (max-width:768px)": {
                      height: "230px",
                      width: "200px",
                      bottom: "115px !important"
                    }
                  }}
                />
              </div>
              <div>
                <Image
                  className="lazyload parallaxImage3 moving-right"
                  src="https://herb-platform-images.imgix.net/wp-content/uploads/2022/05/19115117/image5.jpg"
                  alt="/"
                  css={{
                    height: "320px",
                    width: "550px",
                    "@media screen and (max-width:1024px)": {
                      height: "270px",
                      width: "425px"
                    },
                    "@media screen and (max-width:768px)": {
                      height: "185px",
                      width: "320px"
                    },
                    "@media screen and (max-width:320px)": {
                      width: "285px"
                    }
                  }}
                />
              </div>
              <div>
                <Image
                  className="lazyload parallaxImage4 moving"
                  src="https://herb-platform-images.imgix.net/wp-content/uploads/2022/07/04091414/Rectangle-105.webp"
                  alt="/"
                  css={{
                    height: "425px",
                    width: "385px",
                    "@media screen and (max-width:1024px)": {
                      height: "350px",
                      width: "290px"
                    },
                    "@media screen and (max-width:768px)": {
                      height: "210px",
                      width: "190px"
                    }
                  }}
                />
              </div>
            </div>
          </section>
          <Fade bottom>
            <section className="forTextWithButton">
              <h2 className="TextWithButtonH2 smallText">
                We love cannabis brands
              </h2>
              <p className="TextWithButtonP">
                We seek out, and partner with the leading brands in the
                industry. This way we ensure that our community of cannabis
                tastemakers are always satisfied, and are able to find what they
                look for.
              </p>
              <p className="TextWithButtonP1">
                Through working with best in class brands, we aim to inspire,
                educate, and help our audience discover both old favourites, and
                new passions.
              </p>
              <Button type={"aboutUsPageBtn"} to="/herb-plus">
                partner with us
              </Button>
            </section>
          </Fade>
          <Fade bottom>
            <section className="ourAchievement">
              <div className="achievements">
                <div className="achievementContent">
                  <h1 className="achievementNumbers">#1</h1>
                  <p className="achievementText">
                    Cannabis community for Millenials and Gen Z
                  </p>
                </div>
                <div className="achievementContent">
                  <h1 className="achievementNumbers">14m</h1>
                  <p className="achievementText">
                    Passionate community members
                  </p>
                </div>
                <div className="achievementContent">
                  <h1 className="achievementNumbers">50x</h1>
                  <p className="achievementText">
                    More engagement than Weedmaps & Leafly
                  </p>
                </div>
              </div>
            </section>
          </Fade>
          <section className="parallaxImages2 scroll2">
            <div className="twoParallaxImages" style={{ display: "flex" }}>
              <div>
                <Image
                  className="lazyload pImg moving-bottom-2"
                  src="https://herb-platform-images.imgix.net/wp-content/uploads/2022/07/04092131/getINtouch.webp"
                  alt="/"
                  css={{
                    height: "450px",
                    width: "540px",
                    "@media screen and (max-width:769px)": {
                      height: "185px",
                      width: "225px"
                    }
                  }}
                />
              </div>
              <div>
                <Image
                  className="lazyload pImg1 moving-top-2"
                  src="https://herb-platform-images.imgix.net/wp-content/uploads/2022/07/04092133/Rectangle-107.webp"
                  alt="/"
                  css={{
                    height: "500px",
                    width: "380px",
                    "@media screen and (max-width:769px)": {
                      height: "210px",
                      width: "155px"
                    }
                  }}
                />
              </div>
            </div>
          </section>
          <Fade bottom>
            <section className="getInTouch">
              <h1 className="titleForTouch">Get in touch</h1>
              <div className="buttonsForTouch">
                <div className="partnerButton">
                  <Button type={"aboutUsPageBtn"} to="/herb-plus">
                    partner with us
                  </Button>
                </div>
                <div className="workButton">
                  <Button type={"aboutUsPageBtn"} to="/careers">
                    work with us
                  </Button>
                </div>
                {/* <div className="feedbackButton">
                  <Button type={"aboutUsPageBtn"}>feedback</Button>
                </div> */}
              </div>
              <p>Photo credits: Keti Chikhladze</p>
            </section>
          </Fade>
        </Container>
      </Layout>
    </div>
  );
};

export default AboutUs;
