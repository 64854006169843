import React, { useEffect } from "react";
import { graphql, Link } from "gatsby";
import Layout from "@herb/layouts";
import CategoryBanner from "@herb/components/CategoryBanner";
import SectionHeading from "@herb/components/SectionHeading";
import Container from "@herb/components/Container";
import MegaCardArticle from "@herb/components/Cards/MegaCardArticle";
import ProductCard from "@herb/components/Cards/ProductCard";
import Newscard from "@herb/components/Cards/Newscard";
import TrendingArticleCard from "@herb/components/Cards/TrendingArticleCard";
import ProductNav from "@herb/components/Navbar/ProductNav";
import Slider from "react-slick";
import CategoryTags from "@herb/components/CategoryTags";
import Button from "@herb/components/Button";
import SEO from "@herb/components/seo";
import DisplayAd from "@herb/components/DisplayAd";
import { paths, deployUrl } from "@herb/utils";
import Image from "@herb/components/Image";
import InstantSearch from "@herb/components/Search/InstantSearch";
import { Index, connectInfiniteHits, Configure } from "react-instantsearch-dom";
import Pagination from "@herb/components/Pagination";
import "./index.css";
import { Helmet } from "react-helmet";
import { scriptCreate } from "@herb/utils/scripts";

const isSSR = typeof window === "undefined";
const isBrowser = typeof window !== "undefined";
const documentCheck = typeof document !== "undefined";
// For Slider
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <svg
        className="citySlidertArrow"
        viewBox="0 0 32 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0.999999 1L31 21L0.999999 41" stroke="black" />
      </svg>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <svg
        className="citySlidertArrow"
        viewBox="0 0 32 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M31 1L1 21L31 41" stroke="black" />
      </svg>
    </div>
  );
}
// Settings For slider
var settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,

  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const Category = ({ data, pageContext }) => {
  const {
    tagPosts = {},
    tagProducts = {},
    categoryPosts = {},
    categoryProducts = {},
    category,
    tag,
    cities: { nodes: cities = [] } = {},
    allStrain,
    allRecipe,
    allWordpressWpProductCategory: productCategories
  } = data;

  const {
    strain,
    recipe,
    category: isCategory,
    tag: isTag,
    noindex,
    catPath,
    pageNumber
  } = pageContext;

  let posts = [];
  let products = [];
  if (isCategory) {
    posts = categoryPosts.nodes;
    products = categoryProducts.nodes;
  } else if (isTag) {
    posts = tagPosts.nodes;
    products = tagProducts.nodes;
  } else if (strain) {
    posts = allStrain.nodes;
  } else {
    posts = allRecipe.nodes;
  }

  const [megaArticle, ...articles] = posts || [];

  let breadcrumbs;
  let title;
  let description;
  const url = pageNumber == 0 ? catPath : `${catPath}/${pageNumber + 1}`;

  // Check if url is paginated
  const regex = /\/\d+$/;
  const isPaginated = regex.test(url);

  if (isCategory && !isTag) {
    const { wpParent } = category;
    title = category.contentCategorySeo.seoTitle
      ? category.contentCategorySeo.seoTitle
      : category.name;

    description = category.contentCategorySeo.seoDescription
      ? category.contentCategorySeo.seoDescription
      : category.description;

    if (wpParent === null) {
      breadcrumbs = [
        {
          name: "Read",
          url: `${paths.read}/`
        },
        {
          name: category.name,
          url: `/${(category || {}).slug}/`
        }
      ];
      // url = `/${(category || {}).slug}`;
    } else if (wpParent !== null) {
      breadcrumbs = [
        {
          name: "Read",
          url: `${paths.read}/`
        },
        {
          name: wpParent.node.name,
          url: `/${(wpParent.node || {}).slug}/`
        },
        {
          name: category.name,
          url: `/${(wpParent.node || {}).slug}/${(category || {}).slug}/`
        }
      ];
      // url = `/${(wpParent.node || {}).slug}/${(category || {}).slug}`;
    }
  } else if (isTag) {
    breadcrumbs = [
      {
        name: `Tag: ${tag.name}`,
        url: `${paths.tags}/`
      }
    ];

    title = `Topic: ${tag.name}`;
    description = `${tag.name} articles and products on Herb`;
    // url = `${paths.tags}/${(tag || {}).slug}`;
  } else {
    breadcrumbs = [
      {
        name: "Read",
        url: `${paths.read}/`
      },
      {
        name: "Strains",
        url: `${paths.legacy.strains}/`
      }
    ];
    title = `${
      strain
        ? "The Best Sativa & Indica Weed Strains | Herb Strains"
        : "The Best Cannabis Recipes For Weed Edibles | Herb Recipes"
    }`;
    description = strain
      ? "Explore Herb's detailed guides on the best weed strains to help choose the best marijuana strain for you, whether that be indica, sativa or hybrid."
      : "Easy cannabis recipes to make your favorite weed edibles. Cannabutter, infused cannabis oil, drinks & more. Perfect for the marijuana culinary enthusiast!";
    // url = `${strain ? paths.legacy.strains : paths.legacy.recipes}`;
  }

  const { content_category: { bannerImage } = {} } = category || {};

  const CustomHits = connectInfiniteHits(({ hits, hasMore, refine }) => {
    return (
      <React.Fragment>
        {hits.map((article, index) => {
          return (
            index > 4 && (
              <TrendingArticleCard article={article} key={article.id} />
            )
          );
        })}
        {hasMore && (
          <div className="moreButton">
            <Button
              type={"buttonMore"}
              to="/read/"
              link
              onClick={e => {
                e.preventDefault();
                refine();
              }}
            >
              more
            </Button>
          </div>
        )}
      </React.Fragment>
    );
  });

  const handleMoreClick = () => {
    const algoliaElem = document.getElementById("algolia");
    const wordpressElem = document.getElementById("wordpress");

    if (isBrowser && documentCheck) {
      algoliaElem.style.display = "unset";
      wordpressElem.style.display = "none";
    }
  };

  useEffect(() => {
    scriptCreate();
  });

  return (
    <Layout path={catPath}>
      <>
        {noindex ? (
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
        ) : isPaginated ? (
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
        ) : (
          ""
        )}
      </>
      <SEO
        title={title}
        description={description}
        url={url}
        breadcrumbs={breadcrumbs}
        canonical={`${deployUrl}${url}`}
      />
      {category && bannerImage && <CategoryBanner category={category} />}
      {(strain || recipe) && <CategoryBanner strain={strain} />}
      <Container>
        {tag && !category && (
          <div className="categoryPageTags">
            <CategoryTags tag={tag} />
          </div>
        )}
        {category && !bannerImage && (
          <div className="categoryPageTags">
            <div className="tagsForCategory">
              <button className="tagCategory">
                {String(category.name).toLocaleLowerCase()}
              </button>
            </div>
          </div>
        )}
        {category && (category || {}).slug === "city-guides" && (
          <div className="section4">
            <div className="citySlider">
              <>
                {!isSSR && (
                  <React.Suspense fallback={<div />}>
                    <Slider {...settings}>
                      {(cities || []).map((city, index) => (
                        <div className="cityImage">
                          <Link to={`/city-guides/${(city || {}).slug}`}>
                            <div className="cityBtnImage">
                              <Image
                                className="lazyload ImageOfCity"
                                height={520}
                                width={371}
                                imgixParams={{ fit: "clip", dpr: 1 }}
                                src={
                                  (
                                    (city.content_category || {}).bannerImage ||
                                    {}
                                  ).sourceUrl
                                }
                                alt={
                                  (
                                    (city.content_category || {}).bannerImage ||
                                    {}
                                  ).altText
                                }
                              />
                            </div>
                            <button className="cityBtn" role="link">
                              {city.name}
                            </button>
                          </Link>
                        </div>
                      ))}
                    </Slider>
                  </React.Suspense>
                )}
              </>
            </div>
          </div>
        )}
        <SectionHeading heading="Read" />
        <div className="cardsForAuthorPage">
          {megaArticle && (
            <MegaCardArticle
              article={megaArticle}
              strain={strain}
              recipe={recipe}
              secondHeading={category || strain || recipe ? true : false}
            />
          )}
          <div className="cardContainer">
            <div className="sampleBlogsDiv">
              {(articles.slice(0, 4) || []).map(obj => (
                <Newscard news={obj} strain={strain} />
              ))}
            </div>
          </div>
          <Container>
            <DisplayAd
              visibleProps={{
                margin: "60px 0px"
              }}
              adUnit="21810472093/Homepage/"
              banners={[
                {
                  breakpoint: "mobile",
                  sizes: [[320, 50]]
                },
                {
                  breakpoint: "tablet",
                  sizes: [[320, 50]]
                },
                {
                  breakpoint: "desktop",
                  sizes: [[970, 250]]
                }
              ]}
            />
          </Container>
        </div>
        {products.length === 0 && !strain && !recipe && (
          <>
            <div id="algolia" style={{ display: "none" }}>
              {/* <div style={{ width: "100%" }}>
                <div className="extra-article-div">
                  <InstantSearch>
                    <Index indexName="Dev_Articles">
                      <Configure
                        aroundLatLngViaIP={false}
                        hitsPerPage={13}
                        query="*"
                        filters={
                          !strain && !recipe
                            ? `categories.slug:${(category || {}).slug}`
                            : strain
                            ? `categories:strains`
                            : `categories:recipes`
                        }
                      />
                      <CustomHits />
                    </Index>
                  </InstantSearch>
                </div>
              </div> */}

              <Container>
                <DisplayAd
                  visibleProps={{
                    margin: "100px 0px"
                  }}
                  adUnit="21810472093/Homepage/"
                  banners={[
                    {
                      breakpoint: "mobile",
                      sizes: [[320, 50]]
                    },
                    {
                      breakpoint: "tablet",
                      sizes: [[320, 50]]
                    },
                    {
                      breakpoint: "desktop",
                      sizes: [[970, 250]]
                    }
                  ]}
                />
              </Container>
            </div>
            {posts.length > 0 && (
              <>
                <div id="wordpress" style={{ display: "unset" }}>
                  <div style={{ width: "100%" }}>
                    <div className="extra-article-div">
                      {(posts.slice(5, 9) || []).map(article => (
                        <TrendingArticleCard
                          article={article}
                          key={article.id}
                        />
                      ))}
                    </div>
                  </div>
                </div>
                <div>
                  <Pagination path={catPath} context={pageContext} />
                </div>
              </>
            )}
          </>
        )}

        {products.length === 0 && (strain || recipe) && (
          // <div style={{ width: "100%" }}>
          //   <div className="extra-article-div">
          //     <InstantSearch>
          //       <Index indexName="Dev_Articles">
          //         <Configure
          //           aroundLatLngViaIP={false}
          //           hitsPerPage={9}
          //           query="*"
          //           filters={
          //             !strain && !recipe
          //               ? `categories.slug:${(category || {}).slug}`
          //               : strain
          //               ? `categories:strains`
          //               : `categories:recipes`
          //           }
          //         />
          //         <CustomHits />
          //       </Index>
          //     </InstantSearch>
          //   </div>
          // </div>
          <>
            {posts.length > 0 && (
              <>
                <div id="wordpress" style={{ display: "unset" }}>
                  <div style={{ width: "100%" }}>
                    <div className="extra-article-div">
                      {(posts.slice(5, 9) || []).map(article => (
                        <TrendingArticleCard
                          article={article}
                          key={article.id}
                        />
                      ))}
                    </div>
                  </div>
                </div>
                <div>
                  <Pagination path={catPath} context={pageContext} />
                </div>
              </>
            )}
          </>
        )}
      </Container>

      {products.length > 0 && (
        <div className="categoryProductsBgColor">
          <Container>
            <SectionHeading heading="Products" />
            <ProductNav categories={productCategories} boutique={true} />
            <div className="productsOnCategory">
              {products.map(product => (
                <ProductCard
                  key={product.id}
                  productdetails={product}
                  cardStyles={{ marginRight: "8px" }}
                />
              ))}
            </div>
            <Button
              containerStyles={{ textAlign: "center" }}
              type={"boutiqueButton"}
              role="link"
              to="/products"
            >
              full shop
            </Button>
            <DisplayAd
              visibleProps={{
                marginTop: "180px",
                marginBottom: "40px"
              }}
              adUnit="21810472093/Homepage/"
              banners={[
                {
                  breakpoint: "mobile",
                  sizes: [[320, 50]]
                },
                {
                  breakpoint: "tablet",
                  sizes: [[320, 50]]
                },
                {
                  breakpoint: "desktop",
                  sizes: [[970, 250]]
                }
              ]}
            />
          </Container>
        </div>
      )}
    </Layout>
  );
};

export default Category;

export const pageQuery = graphql`
  query(
    $slug: String!
    $strain: Boolean!
    $recipe: Boolean!
    $category: Boolean!
    $tag: Boolean!
    $skip: Int!
    $limit: Int!
  ) {
    ...allWordpressWpProductCategory

    tag: wordpressWpTag(slug: { eq: $slug }) @include(if: $tag) {
      id
      name
      slug
    }
    category: wordpressWpCategory(slug: { eq: $slug }) @include(if: $category) {
      id
      name
      slug
      contentCategorySeo {
        seoDescription
        seoTitle
      }
      content_category {
        bannerImage {
          sourceUrl
          altText
          caption
        }
      }
      wpParent {
        node {
          name
          id
          slug
        }
      }
    }

    tagPosts: allWordpressWpPost(
      filter: { tags: { nodes: { elemMatch: { slug: { eq: $slug } } } } }
      sort: { fields: [date], order: DESC }
      limit: $limit
      skip: $skip
    ) @include(if: $tag) {
      nodes {
        ...Post
      }
    }
    cities: allWordpressWpCategory(
      filter: {
        wpParent: { node: { slug: { eq: "city-guides" } } }
        count: { gt: 0 }
      }
    ) @include(if: $category) {
      nodes {
        name
        slug
        content_category {
          bannerImage {
            sourceUrl
            altText
          }
        }
      }
    }

    tagProducts: allWordpressWpProduct(
      filter: { tags: { nodes: { elemMatch: { slug: { eq: $slug } } } } }
      sort: { fields: [date], order: DESC }
      limit: 5
    ) @include(if: $tag) {
      nodes {
        ...Product
      }
    }

    categoryPosts: allWordpressWpPost(
      filter: { categories: { nodes: { elemMatch: { slug: { eq: $slug } } } } }
      sort: { fields: [date], order: DESC }
      limit: $limit
      skip: $skip
    ) @include(if: $category) {
      nodes {
        ...Post
      }
    }

    categoryProducts: allWordpressWpProduct(
      filter: {
        productCategories: { nodes: { elemMatch: { slug: { eq: $slug } } } }
      }
      sort: { fields: [date], order: DESC }
      limit: 5
    ) @include(if: $category) {
      nodes {
        ...Product
      }
    }

    allRecipe(sort: { fields: date, order: DESC }, limit: $limit, skip: $skip)
      @include(if: $recipe) {
      nodes {
        ...Recipe
      }
    }

    allStrain(sort: { fields: date, order: DESC }, limit: $limit, skip: $skip)
      @include(if: $strain) {
      nodes {
        ...Strain
      }
    }
  }
`;
