import React from "react";
import Image from "@herb/components/Image";
import "./index.css";

const FoundersCard = ({
  image,
  name,
  designation,
  company,
  founder = 1,
  marginRight = false
}) => {
  return (
    <div className={`founderCard ${marginRight ? "marginRight" : ""}`}>
      <div className={`fonderCardUpper `}>
        <Image
          className="quotes lazyload"
          src="https://herb-platform-images.imgix.net/wp-content/uploads/2023/04/11072622/unnamed-file.png"
        />
        <Image className="founderImage lazyload" src={image} />
        <p className="founderName">{name}</p>
        <div>
          <p className="founderDesignation">{designation}</p>
          <p className="founderDesignation">{company}</p>
        </div>
      </div>
      <div className="divider"></div>

      {founder === 1 ? (
        <p className="founderDescription">
          <span
            style={{
              color: "#F76F6F",
              fontFamily: "Graphik Medium"
            }}
          >
            “Herb continues to give us consistent ROAS
          </span>{" "}
          after years of working with them. Our dedicated account manager is
          watching so we don’t have to!”
        </p>
      ) : founder === 2 ? (
        <p className="founderDescription">
          “Herb’s team has been a pleasure to work with, always incredibly
          helpful and responsive!{" "}
          <span
            style={{
              color: "#F76F6F",
              fontFamily: "Graphik Medium"
            }}
          >
            The social amplification has helped provide a great boost in brand
            awareness
          </span>{" "}
          around the holidays!”
        </p>
      ) : (
        <p className="founderDescription">
          “The Herb team has been instrumental in helping us{" "}
          <span
            style={{
              color: "#F76F6F",
              fontFamily: "Graphik Medium"
            }}
          >
            expand our brand presence,
          </span>{" "}
          and they are great to work with.”
        </p>
      )}
    </div>
  );
};

export default FoundersCard;
